import React, { useState } from "react";
import Typed from "react-typed";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import MainLayout from "../layout/MainLayout";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";

const CheckoutForm = ({ data }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }
  };

  return (
    <MainLayout>
      <div
        className="jumbotron jumbotron-fluid"
        style={{
          background: "#F7F9FC",
          borderBottom: "1px solid #DEE0F2",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1 className="display-4">Post a Job</h1>
              <div className="lead">
                Reach <strong>brilliant talent</strong> looking for jobs in the Salesforce ecosystem.
                <br />
                Join hundreds of companies advertising positions on PlatformJobs.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
            <form name="post-a-job" onSubmit={handleSubmit} data-netlify="true">
              <div className="card border-secondary">
                <h5 className="card-header">Job Details</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label>Job Title *</label>
                    <input
                      type="text"
                      name="job-title"
                      className="form-control"
                      placeholder="Example: Salesforce Technical Architect"
                      minLength={10}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Location *</label>
                    <input
                      type="text"
                      name="job-location"
                      className="form-control"
                      placeholder="Example: London, UK"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Job Description *</label>
                    <textarea name="job-description" className="form-control" minLength={100} required rows={10} />
                    <small className="form-text text-muted">
                      Feel free to use Markdown markup - it will be converted when posted.
                    </small>
                  </div>
                  <div className="form-group">
                    <label>Where should people apply for this? *</label>
                    <input
                      type="text"
                      name="job-apply-url"
                      className="form-control"
                      placeholder="Example: https://careerwebsite.com/12345/apply, or mailto:hr@example.com"
                      required
                    />
                    <small className="form-text text-muted">
                      Use a link or email address to submit applications to.
                    </small>
                  </div>
                </div>
              </div>
              <br />
              <div className="card border-secondary">
                <h5 className="card-header">Company Details</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label>Company Name *</label>
                    <input
                      type="text"
                      name="company-name"
                      className="form-control"
                      placeholder="Example: Acme Inc."
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Company Website</label>
                    <input
                      type="url"
                      name="company-url"
                      className="form-control"
                      placeholder="Example: https://acme.com/"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="card border-secondary">
                <h5 className="card-header">Payment</h5>
                <div className="card-body">
                  <div className="form-group">
                    <label>Email Address *</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Example: celine@example.com"
                      required
                    />
                    <small className="form-text text-muted">We will email you when the job listing is published.</small>
                  </div>

                  <div className="form-group">
                    <label>Credit Card</label>
                    <CardElement
                      className={"form-control"}
                      options={{
                        style: {
                          base: {
                            fontSize: "18px",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="text-center">
                    <button type="submit" className={"btn btn-success btn-lg"} disabled={!stripe}>
                      Post your job for £250
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const stripePromise = loadStripe("pk_test_ZueQ9hTqK8dxj03MRr9xESQd");

export default () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);
